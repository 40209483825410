import Swal from 'sweetalert2';

export default class AirLSTController {
    constructor($scope, $injector) {
        this.scope = $scope;
        this.injector = $injector;
        this.location = this.injector.get('$location');
        this.alSdk = this.injector.get('AirLSTSdkService');
        this.api = this.injector.get('$http');

        // If you want to override api url
        // this.alSdk.apiUrl = 'http://localhost:8800/lp';

        this.displayPage = false;
        this.guestlistUid = null;
        this.rsvpCode = null;
        this.companyUid = null;
        this.eventInformation = null;

        this.checkinSuccessful = false;
        this.statusText = '';
        this.redirectUrl = '';
        this.currentRedirectSecond = 1;
        this.secondsUntilRedirect = 5; // Change this for duration until redirect

        this._loadUrlParameters();
        this._loadGuestlistInformation()
            .then(() => {
                this._doCheckIn();
            });
    }

    _loadUrlParameters() {
        const urlParameters = this.location.search();
        let parametersValid = true;

        if (
            !urlParameters.guestlist
            || !urlParameters.company
            || !urlParameters.rsvp
        ) {
            this._throwInvalidParameters();
            return;
        }

        this.guestlistUid = urlParameters.guestlist;
        this.companyUid = urlParameters.company;
        this.rsvpCode = urlParameters.rsvp;
    }

    _loadGuestlistInformation() {
        this.alSdk.companyUid = this.companyUid;
        this.alSdk.guestlistUid = this.guestlistUid;

        return this.alSdk.retrieveGuestlistInformation()
            .then(
                (eventInfo) => {
                    this.eventInformation = eventInfo;
                },
                (e) => {
                    this._throwInvalidParameters();
                });
    }

    _doCheckIn() {
        return this.api.post(`${this.alSdk.apiUrl}/guestlists/${this.guestlistUid}/rsvps/${this.rsvpCode}/check-in`)
            .then((response) => {
                this.checkinSuccessful = true;
                this.redirectUrl = response.data.data.redirect_url;
                this.statusText = this.eventInformation.settings.online_checkin_settings.text_1;
                this._checkRedirectLoop();
                this.displayPage = true;
            }, (e) => {
                if (e.status === 404) {
                    this.checkinSuccessful = false;
                    this._throwInvalidParameters();
                    this.displayPage = false;
                } else {
                    this.statusText = e.data.error;
                    this.redirectUrl = e.data.redirect_url;
                    this.checkinSuccessful = !!this.redirectUrl;
                    this._checkRedirectLoop();
                    this.displayPage = true;
                }
            });
    }

    _throwInvalidParameters() {
        Swal.fire({
            icon: 'error',
            title: 'Invalid parameters',
            showCancelButton: false,
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        });
    }

    _checkRedirectLoop() {
        if(this.redirectUrl) {
            this.injector.get('$timeout')(() => {
                if(this.currentRedirectSecond < this.secondsUntilRedirect) {
                    this.currentRedirectSecond++;
                    this._checkRedirectLoop();
                } else {
                    window.location.replace(this.redirectUrl);
                }
            }, 1000);
        }
    }
}

AirLSTController.$inject = [
    '$scope',
    '$injector'
];
