import '../styles/index.scss';

import angular from 'angular';
import './vendor/airlst-sdk_1.1.1.min';
import AirLSTController from './controllers/airlst-controller';

window.angular = angular;

angular.module('app', [
    'AirLST-SDK'
])
    .directive('ngEnter', [
        function () {
            return function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.vm.loadRsvp();
                        });

                        event.preventDefault();
                    }
                });
            };
        }
    ])

    .controller('AirLSTCtrl', AirLSTController);

require('./filters/nl2br');
